import React from 'react'
import Select from './Select';
import Button from './Button';
import mapImg from 'images/icons/map.svg'
import searchImg from 'images/icons/search.svg'
import AsyncSelect from './AsyncSelect';
import geo from 'utils/geo';
type Props = {
    action:() => void
    btnText:string
}

const SettingsForm = ({action,btnText}: Props) => {
  return (
    <form>
        <div className="form-row">
          <label htmlFor="lang">Язык приложения:</label>
          <Select
            options={[
              { label: "Русский", value: "ru" },
              { label: "Украинский", value: "ukr" },
            ]}
            defaultValue="ru"
            onSelect={() => {}}
          />
        </div>
        <div className="form-row">
          <label htmlFor="lang">Адрес или геолокация:</label>
          <AsyncSelect
            fetchOptions={async (query:string) => {
                const result = await geo({type:'search',query:query})
                console.log("🚀 ~ fetchOptions={ ~ result:", result)
                return [{label:'',value:''}]
            }}
            defaultValue={''}
            icon={mapImg}
            onSelect={() => {}}
          />
        </div>
        <div className="form-row">
          <label htmlFor="lang">Школа:</label>
          <Select
            options={[
              { label: "ВСОШ№1", value: 1 },
              { label: "ВСОШ№2", value: 2 },
              { label: "ВСОШ№3", value: 3 },
              { label: "ВСОШ№4", value: 4 },
              { label: "ВСОШ№5", value: 5 },
            ]}
            icon={searchImg}
            isSearchable
            defaultValue="ru"
            onSelect={() => {}}
          />
        </div>
        <div className="form-row">
          <label htmlFor="lang">Класс:</label>
          <Select
            options={[
              { label: "5А", value: "5А" },
              { label: "5Б", value: "5Б" },
              { label: "5В", value: "5В" },
              { label: "5Г", value: "5Г" },
            ]}
            defaultValue="ru"
            onSelect={() => {}}
          />
        </div>
        <Button
          text={btnText}
          onClick={(e) => {
            e.preventDefault();
            action()
          }}
        />
      </form>
  )
}

export default SettingsForm