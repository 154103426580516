import Compliments from "pages/Compliments/Compliments";
import CreateSurvey from "pages/CreateSurvey/CreateSurvey";
import Entry from "pages/Entry/Entry";
import Home from "pages/Home/Home";
import Loading from "pages/Loading/Loading";
import Settings from "pages/Settings/Settings";
import Tasks from "pages/Tasks/Tasks";
import { RouteObject } from "react-router-dom";

type Route = {
  route:RouteObject
  title?:string
}

const routes:RouteObject[] = [
  {
    path: "/",
    element: <Loading />,
  },
  {
    path: "/entry",
    element: <Entry />,
  },
  {
    path: "/home",
    element: <Home />,
  },
  {
    path: "/settings",
    element: <Settings/>,
  },
  {
    path: "/compliments",
    element: <Compliments/>,
  },
  {
    path: "/tasks",
    element: <Tasks/>,
  },
  {
    path: "/create-survey",
    element: <CreateSurvey/>,
  },
  ]
  export default routes