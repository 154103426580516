import { createContext, ReactNode, useEffect, useState } from "react"
import AppContextType from "types/app-context";
// import getUserData, { ErrorNotFound } from "api/get-user-data";
import AppContext from "utils/app-context";
import tg from "utils/tg";
import UserData from "types/user-data";
export default () => {
    const [user, setUser] = useState<UserData | null>(null)
   
    // const updateUserInfo = async () => {
    //     const result = await getUserData()
    //     if (result === null || (result as ErrorNotFound).error) tg.showAlert('Some kind of error')
    //     else setUser(result as UserData)
    // }

    const AppContextValue:AppContextType = {
        user,
        // updateUserInfo,
        setUser
    }

    useEffect(() => {
        // updateUserInfo()
    }, [])
   
    return {AppContext,AppContextValue}
}