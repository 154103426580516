import React from 'react'
import PageWrapper from 'UI/PageWrapper'
import userImg from 'images/icons/user.svg'
import bellImg from 'images/icons/bell.svg'
import starImg from 'images/icons/star.svg'
import addImg from 'images/icons/circle-plus.svg'
import refreshImg from 'images/icons/refresh-ccw.svg'
import nextImg from 'images/icons/circle-chevron-right.svg'

import './Home.css'
import Button from 'UI/Button'
import { format } from 'path'
import formatNumber from 'utils/format-number'
import { useNavigate } from 'react-router-dom'
import UserBalances from 'UI/UserBalances'
type Props = {}

const Home = (props: Props) => {
    const navigate = useNavigate()
  return (
    <PageWrapper className='home-page'>
        <header>
            <nav>
                <ul>
                    <li onClick={() => navigate('/settings')}>
                        <img src={userImg}/>
                    </li>
                  <li onClick={() => navigate('/compliments')}>
                        <span className='notif-counter'>12</span>
                        <img src={bellImg}/>
                    </li>
                  <li onClick={() => navigate('/tasks')}>
                        <img src={starImg}/>
                    </li>
                </ul>
            </nav>
            <Button text='Опрос' icon={addImg} onClick={() => navigate('/create-survey')}/>
        </header>
        <section className='page-body'>
            <UserBalances/>
            <div className="survey-container">
                <h2>Опрос 2 из 10</h2>
                <div className="survey-progress">
                    <span className='survey-progress-bar' style={{width:`${20}%`}}/>
                </div>

                <div className="survey-question-block">
                    <p className="survey-question">Кто будет вратарём на физре?</p>

                    <ul className='survey-question-variants'>
                        <li>Лиза</li>
                        <li>Рома</li>
                        <li>Дима</li>
                        <li>Даша</li>
                    </ul>
                    <Button text='Голосовать'/>
                    <div className="survey-actions">
                        {/* <button>
                            <span></span>
                            <img src={refreshImg} alt="" />
                        </button>
                        <button>
                            <span></span>
                            <img src={} alt="" />
                        </button> */}
                    <Button text='Поменять ответы' icon={refreshImg}/>
                    <Button text='Следующий' icon={nextImg}/>
                    </div>
                </div>
            </div>
        </section>
    </PageWrapper>
  )
}

export default Home