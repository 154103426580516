import React from "react";
import HeaderBack from "UI/HeaderBack";
import PageWrapper from "UI/PageWrapper";
import fireImg from 'images/fire.png'
import './Compliments.css'
import arrowImg from 'images/icons/chevron-right.svg'
type Props = {};

const Compliments = (props: Props) => {
  return (
    <PageWrapper className="settings-page">
      <HeaderBack />
      <section className="page-body">
        <h2>Комплименты</h2>
        <ul className="comliments-list">
            {Array.from({length:20}).map((n,i) => (
                <li className="comliments-item">
                    <div className="comliments-item-left">
                        <span className="comliments-position">{i+1}</span>
                        <img src={fireImg} alt="" />
                        <p className="comliments-text">Текст</p>
                    </div>
                    <div className="comliments-item-right">
                        <span>2ч назад</span>
                        <img src={arrowImg} alt="" />
                    </div>
                </li>
            ))}
        </ul>
      </section>
    </PageWrapper>
  );
};

export default Compliments;
