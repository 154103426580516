import axios, { AxiosResponse } from "axios";

interface ReverseGeo {
  type: "reverse";
  lat?: number;
  lon?: number;
}

interface SearchGeo {
  type: "search";
  query: string;
}
export interface ResultAddress{
  town:string;
  quarter:string;
  neighbourhood:string;
  house_number:string;
  road: string;
  suburb: string;
  city: string;
  county: string;
  state: string;
  "ISO3166-2-lvl4": string;
  region: string;
  postcode: string;
  country: string;
  country_code: string;
}
export interface Result {
  place_id: number;
  licence: string;
  osm_type: string;
  osm_id: number;
  lat: string;
  lon: string;
  category: string;
  type: string;
  place_rank:number;
  importance: number;
  addresstype: string;
  name: string;
  display_name: string;
  address: ResultAddress,
  boundingbox: [string,string,string,string]
}


export default async (obj: SearchGeo | ReverseGeo):Promise<Result | Result[] | false> => {
  try {
    const url = new URL(`https://nominatim.openstreetmap.org`);
    url.pathname = obj.type;
    url.searchParams.append("format", "jsonv2");
    const axiosReqConfig = { headers: { "accept-language": "ru" } };
    let result: AxiosResponse;

    switch (obj.type) {
      case "search":
        url.searchParams.append("q", obj.query);
        result = await axios.get(url.toString(), axiosReqConfig);
        return result.data as Result[];

      case "reverse":
        url.searchParams.append("lat", String(obj.lat));
        url.searchParams.append("lon", String(obj.lon));
        result = await axios.get(url.toString(), axiosReqConfig);
        return result.data as Result;

      default:
        break;
    }
    return false
  } catch (err) {
    console.log(err);
    return false;
  }
};