import React, { useEffect, useState } from "react";
import PageWrapper from "UI/PageWrapper";
import "./Loading.css";
import logo from "images/logo.svg";
import { useNavigate } from "react-router-dom";
type Props = {};

const Loading = (props: Props) => {
  const [progress, setProgress] = useState(20);
  const navigate = useNavigate();

  // Effect for animating the progress
  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prev) => {
        const nextProgress = prev + 1; // Increase progress by 5
        if (nextProgress >= 100) {
          clearInterval(interval);
          setTimeout(() => {
            navigate("/entry"); // Navigate to /game when progress reaches 100
          }, 200); // Optional delay before navigation
        }
        return Math.min(nextProgress, 100); // Ensure it doesn't go beyond 100
      });
    }, 20); // Increase progress every 0.5 seconds

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, [navigate]);

  return (
    <PageWrapper className="loading-page">
      <div className="loading-logo">
        <img src={logo} alt="" />
      </div>
      <div className="loading-progress">
        <h1 className="loading-progress-value">{progress}%</h1>
        <span className="loading-progress-container">
          <span className="loading-progress-bar" style={{width:`${progress}%`}}/>
        </span>
      </div>
    </PageWrapper>
  );
};

export default Loading;
