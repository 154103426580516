import { HTMLMotionProps, motion } from "framer-motion";
import React from "react";
import tg from "utils/tg";

interface Props extends HTMLMotionProps<"button"> {
  text: string;
  icon?: string;
}

const Button = (props: Props) => {
  return (
    <motion.button
      whileTap={{ scale: 0.9 }}
      onPointerDown={(e) => {
        tg.HapticFeedback.selectionChanged();
      }}
      {...props}
      className={`btn ${props.className ? props.className : ''}`}
    >
      {props.text}
      {props.icon && <img src={props.icon} />}
    </motion.button>
  );
};

export default Button;
