import React from 'react'
import Button from './Button'
import backImg from 'images/icons/circle-chevron-left.svg'
import { useNavigate } from 'react-router-dom'
type Props = {}

const HeaderBack = (props: Props) => {
    const navigate = useNavigate()
  return (
    <header className='header-back'>
        <Button text='' icon={backImg} onClick={() => navigate(-1)}/> 
    </header>
  )
}

export default HeaderBack