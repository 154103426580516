import React, { useState, useEffect } from 'react';
import Option from 'types/option'
import arrow from 'images/icons/polygon.svg'

type AsyncSelectProps = {
  fetchOptions: (searchQuery: string) => Promise<Option[]>;
  defaultValue: Option['value'];
  icon?: string;
  onSelect: (option: Option) => void;
  placeholder?: string;
};

const AsyncSelect = ({ fetchOptions, defaultValue, icon, onSelect, placeholder }: AsyncSelectProps) => {
  const [options, setOptions] = useState<Option[]>([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedOption, setSelectedOption] = useState(defaultValue);

  useEffect(() => {
    let isMounted = true;

    const loadOptions = async () => {
      setLoading(true);
      try {
        const fetchedOptions = await fetchOptions(searchQuery);
        if (isMounted) {
          setOptions(fetchedOptions);
        }
      } catch (error) {
        console.error('Error fetching options:', error);
      } finally {
        setLoading(false);
      }
    };

    if (searchQuery) {
      loadOptions();
    }

    return () => {
      isMounted = false;
    };
  }, [searchQuery, fetchOptions]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const handleSelectChange = (option: Option) => {
    setSelectedOption(option.value);
    onSelect(option);
  };

  return (
    <div className="form-select-container"> {/* Используем класс "select-container" */}
      <div className="select-input-wrapper">
      <div className="form-select-icon">
        <img src={icon || arrow} alt="" />
      </div>
        <input
          type="text"
          value={searchQuery}
          onChange={handleSearchChange}
          placeholder={placeholder || "Поиск..."}
          className="form-select-input" 
        />
      </div>
      {loading ? (
        // <div className="select-loading">Загрузка...</div> 
        <div className="select-loading"></div> 
      ) : (
        <ul className="select-options"> 
          {options.map((option) => (
            <li
              key={option.value}
              className="select-option" 
              onClick={() => handleSelectChange(option)}
            >
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default AsyncSelect;
