import React from 'react'
import heartImg from 'images/heart.png'
import coinImg from 'images/coin.png'
import formatNumber from 'utils/format-number'
type Props = {}

const UserBalances = (props: Props) => {
  return (
    <div className="user-balances">
                <div className="user-balance">
                    <img src={heartImg} alt="" />
                    <span>Комплименты: <b>{formatNumber(226)}</b></span>
                </div>
                <div className="user-balance">
                    <img src={coinImg} alt="" />
                    <span>Монеты: <b>{formatNumber(1326)}</b></span>
                </div>
            </div>
  )
}

export default UserBalances