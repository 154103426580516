import { motion } from 'framer-motion';
import React, { useState, useEffect } from 'react';
import Option from 'types/option';
import arrow from 'images/icons/polygon.svg'
type Props = {
  options: Option[];
  onSelect: (value: Option['value']) => void;
  placeholder?: string;
  defaultValue?: string;
  icon?:string
  isSearchable?: boolean;  // Новый пропс для указания возможности поиска
};

const Select: React.FC<Props> = ({
  options,
  onSelect,
  placeholder = 'Выберите из списка',
  defaultValue,
  icon,
  isSearchable = false, // По умолчанию разрешён поиск
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredOptions, setFilteredOptions] = useState<Option[]>(options);
  const [selectedOption, setSelectedOption] = useState<Option | null>(
    options.find(option => option.value === defaultValue) || null
  );

  useEffect(() => {
    if (searchQuery) {
      setFilteredOptions(
        options.filter(option =>
          option.label.toLowerCase().includes(searchQuery.toLowerCase())
        )
      );
    } else {
      setFilteredOptions(options);
    }
  }, [searchQuery, options]);

  const handleSelect = (option: Option) => {
    setSelectedOption(option);
    onSelect(option.value);
    setIsOpen(false);
    setSearchQuery(option.label); // Устанавливаем текст выбранной опции в инпут
  };

  return (
    <motion.div className={`form-select-container ${isOpen ? 'open' : ''}`}>
      <div className="form-select-icon">
        <img src={icon || arrow} alt="" />
      </div>
      {isSearchable ? (
        <input
          type="text"
          value={isOpen ? searchQuery : selectedOption?.label || searchQuery}
          onClick={() => setIsOpen(!isOpen)}  // Открытие/закрытие выпадающего списка
          onChange={(e) => {
            setSearchQuery(e.target.value); // Обновляем поисковый запрос
            setSelectedOption(null); // Сбрасываем выбранную опцию при вводе текста
            setIsOpen(true); // Открываем список при вводе
          }}
          placeholder={placeholder}
          className="form-select-input"
        />
      ) : (
        <div
          className="form-select-header"
          onClick={() => setIsOpen(!isOpen)}  // Открытие/закрытие выпадающего списка
        >
          {selectedOption ? selectedOption.label : placeholder}
        </div>
      )}
      {isOpen && (
        <motion.div 
          initial={{ height: 0 }} // Начальное состояние
          animate={{ height: isOpen ? 'auto' : 0 }} // Анимация при открытии
          transition={{ duration: 0.2 }} // Длительность анимации
          className="form-select-dropdown"
        >
          <motion.ul className="form-select-options">
            {(isSearchable ? filteredOptions : options).length > 0 ? (
              (isSearchable ? filteredOptions : options).map(option => (
                <li
                  key={option.value}
                  onClick={() => handleSelect(option)}
                  className="form-select-option"
                >
                  {option.label}
                </li>
              ))
            ) : (
              <li className="form-select-no-options">Ничего не найдено</li>
            )}
          </motion.ul>
        
        </motion.div>
      )}
    </motion.div>
  );
};

export default Select;
