import React from "react";
import HeaderBack from "UI/HeaderBack";
import PageWrapper from "UI/PageWrapper";
import fireImg from "images/fire.png";
import heartImg from "images/heart.png";
import starImg from "images/star.png";
import arrowImg from "images/icons/chevron-right.svg";
import clockImg from "images/icons/clock.svg";
import doneImg from "images/icons/circle-check.svg";
import coinImg from "images/coin.png";
import "./Tasks.css";
type Props = {};

const Tasks = (props: Props) => {
  return (
    <PageWrapper className="settings-page">
      <HeaderBack />
      <section className="page-body">
        <h2>Задания</h2>
        <ul className="comliments-list tasks-list">
          <li className="comliments-item tasks-item">
            <div className="comliments-item-left">
              <span className="comliments-position">1</span>
              <img src={fireImg} alt="" />
              <p className="comliments-text">Добавить 🔥 в TG имя</p>
            </div>
            <div className="comliments-item-right">
              <span className="task-reward">
                +10
                <img src={coinImg} alt="" />
              </span>
              <img src={arrowImg} alt="" />
            </div>
          </li>
          <li className="comliments-item tasks-item">
            <div className="comliments-item-left">
              <span className="comliments-position">1</span>
              <img src={starImg} alt="" />
              <p className="comliments-text">+2 к уровню
              в рейтинге за 2 дня</p>
            </div>
            <div className="comliments-item-right">
              <span className="task-reward">
                +10
                <img src={coinImg} alt="" />
              </span>
              <img src={clockImg} alt="" />
            </div>
          </li>
        </ul>
        <p className="task-list-title">Выполнено:</p>
        <ul className="comliments-list tasks-list tasks-list--done">
          <li className="comliments-item tasks-item">
            <div className="comliments-item-left">
              <span className="comliments-position">1</span>
              <img src={heartImg} alt="" />
              <p className="comliments-text">Получить
              10 комплиментов</p>
            </div>
            <div className="comliments-item-right">
              <span className="task-reward">
                +10
                <img src={coinImg} alt="" />
              </span>
              <img src={doneImg} alt="" />
            </div>
          </li>
          <li className="comliments-item tasks-item">
            <div className="comliments-item-left">
              <span className="comliments-position">1</span>
              <img src={heartImg} alt="" />
              <p className="comliments-text">Получить
              22 комплимента</p>
            </div>
            <div className="comliments-item-right">
              <span className="task-reward">
                +10
                <img src={coinImg} alt="" />
              </span>
              <img src={doneImg} alt="" />
            </div>
          </li>
        </ul>
      </section>
    </PageWrapper>
  );
};

export default Tasks;
