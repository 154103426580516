import React from 'react'
import './CreateSurvey.css'
import HeaderBack from 'UI/HeaderBack'
import PageWrapper from 'UI/PageWrapper'
import Button from 'UI/Button'
import imgDown from 'images/icons/image-down.svg'
type Props = {}

const CreateSurvey = (props: Props) => {
  return (
    <PageWrapper className="settings-page">
      <HeaderBack />
      <section className="page-body">
        <h2>Создать опрос</h2>
        <Button text='Загрузить своё изображение.' icon={imgDown} className='load-image'/>
        <form className="survey-form">
            <textarea  name="" id="" placeholder='Вопрос...'></textarea>
            <p>Чтобы указать верный ответ дважды нажмите на нужный блок.</p>
            <div className="survey-form-answers">
                <label htmlFor="answer1">
                    <input id='answer1' type="text" placeholder='Ответ 1...'/>
                </label>
                <label htmlFor="answer2">
                    <input id='answer2' type="text" placeholder='Ответ 2...'/>
                </label>
                <label htmlFor="answer3">
                    <input id='answer3' type="text" placeholder='Ответ 3...'/>
                </label>
                <label htmlFor="answer4">
                    <input id='answer4' type="text" placeholder='Ответ 4...'/>
                </label>
            </div>
            <Button text='Опубликовать' />
        </form>
      </section>
    </PageWrapper>
  )
}

export default CreateSurvey