import React from 'react'
import Button from 'UI/Button'
import PageWrapper from 'UI/PageWrapper'
import UserBalances from 'UI/UserBalances'
import './Settings.css'
import refreshImg from 'images/icons/refresh-ccw-black.svg'
import HeaderBack from 'UI/HeaderBack'
import SettingsForm from 'UI/SettingsForm'
type Props = {}

const Settings = (props: Props) => {
  return (
    <PageWrapper className='settings-page'>
        <HeaderBack/>
        <section className='page-body'>
            <h2>Статистика и настройки</h2>
            <UserBalances/>
           <div className="compliment-container">
            <h5>Количество комплиментов/день:</h5>
            <ul className='compliment-list'>
                {compliments.map(({day,val}) => {
                    return (
                        <li className='compliment-day-item'>
                            <span className='compliment-day-value'>{val}</span>
                            <div className='compliment-day-progreess'>
                                <span className='compliment-day-progreess-bar'
                                 style={{height:`${val*5}%`}}/>
                            </div>
                            <span className='compliment-day-week'>{day}</span>
                        </li>
                    )
                })}
            </ul>
           </div>
        <SettingsForm action={() => {}} btnText="Сохранить"/>
            <div className='stat-reset'>
                <span>Сброс статистики:</span>
                <Button text='Сбросить' icon={refreshImg}/>
            </div>
            <div className='stat-reset'>
                <span>Выйти</span>
            </div>
        </section>
    </PageWrapper>
  )
}

const compliments = [
    {
        val:12,
        day:'ПН'
    },
    {
        val:10,
        day:'ВТ'
    },
    {
        val:8,
        day:'СР'
    },
    {
        val:4,
        day:'ЧТ'
    },
    {
        val:9,
        day:'ПТ'
    },
    {
        val:10,
        day:'СБ'
    },
    {
        val:9,
        day:'ВС'
    },
]

export default Settings