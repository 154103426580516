import React from "react";
import PageWrapper from "UI/PageWrapper";
import Select from "UI/Select";
import "./Entry.css";
import Button from "UI/Button";
import { useNavigate } from "react-router-dom";
import Settings from "pages/Settings/Settings";
import SettingsForm from "UI/SettingsForm";
type Props = {};

const Entry = (props: Props) => {
  const navigate = useNavigate();
  return (
    <PageWrapper className="entry-page">
      <h1>Добро пожаловать!</h1>
      <SettingsForm action={() => navigate('/home')} btnText="Продолжить"/>
    </PageWrapper>
  );
};

export default Entry;
